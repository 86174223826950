
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import QuickPurchasePageTitle from "@/components/landingPages/quickPurchase/QuickPurchasePageTitle.vue";

@Component({
  name: "QuickPurchaseThankYou",
  components: { QuickPurchasePageTitle },
})
export default class QuickPurchaseThankYou extends Vue {
  @Inject() readonly quickPurchaseBus!: Vue;
  @Prop() private planName!: string;
  @Prop() private platformUrl!: string;

  toPlatform() {
    this.quickPurchaseBus.$emit("shouldGoToPlatform", this.platformUrl);
  }

  mounted() {
    this.$jfNotification.clearAll();
    this.quickPurchaseBus.$emit("shouldDisplayHostedPlanCta", false);
  }
}
